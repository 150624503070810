import React from "react";
import {graphql} from "gatsby";
import {useTranslation, Helmet} from "gatsby-plugin-react-i18next";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import {Box, Typography, Grid} from "@mui/material";
import SectionTitle from "components/atoms/SectionTitle";

export default function JakPodpisacDeklaracjePage() {
  const {t} = useTranslation();
  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - {t("pageTitle")}</title>
      </Helmet>
      <Section padding={2}>
        <SectionTitle>
          {t("pageTitle")}
        </SectionTitle>
        <Typography
          dangerouslySetInnerHTML={{
            __html: t("declaration-howTo-description")
          }}
        />
        <Typography sx={{pt: 4}}>
          {t("declaration-howTo-sign-header")}
        </Typography>
        <Box component="ol" sx={{display: "block", m: 0}}>
          {
            t("declaration-howTo-sign-list", {returnObjects: true}).map((content, index) => (
              <li key={index} dangerouslySetInnerHTML={{__html: content}}/>
            ))
          }
        </Box>
        <Typography variant="h3" sx={{pt: 4, pb: 4, textAlign: "center"}}>
          {t("declaration-video-header")}
        </Typography>
        <Grid container spacing={2} justifyContent="center">

              <Grid item xs={12} lg={4}>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/lcZvoqyvoCg"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/feiiY5RunIU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Grid>

        </Grid>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["jak-podpisac-deklaracje", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
